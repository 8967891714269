import { RatingType } from '@frontend/shared/types'

export const STORAGE_TOKEN_EXPIRES_AT = 'tokenExpiresAt'
export const STORAGE_REFRESH_TOKEN_EXPIRES_AT = 'refreshTokenExpiresAt'
export const COOKIE_XSRF_TOKEN = 'xsrf_token'
export const CONTENT_SECURITY_POLICY_NONCE = 'bec3CottPXMUXjhTO14B5Q25011mebbH'
export const HEADER_XSRF_TOKEN = 'X-XSRF-TOKEN'
export const DATE_FORMAT = {
  abbreviatedMonthDate: 'MMM D, YYYY',
  date: 'YYYY-MM-DD',
  dateISO: 'YYYY-MM-DDTHH:mm:ss.sssZ',
  fullDate: 'MM/DD/YYYY',
  fullDateTime: 'MM/DD/YYYY h:mm a',
  fullMonthName: 'MMMM',
}
export const DEFAULT_COUNTRY = 'SE'
export const DEFAULT_LANGUAGE_CODE = 'sv-SE'
export const IS_BROWSER = typeof window !== 'undefined'
export const LANGUAGE_CODE = IS_BROWSER ? window.navigator.language || DEFAULT_LANGUAGE_CODE : DEFAULT_LANGUAGE_CODE
export const KEY = {
  enter: 'Enter',
  escape: 'Escape',
  spacebar: ' ',
}
export const SERVICE_URL = {
  beUrl: '/api',
}
export const SOCIAL_MEDIA = {
  email: 'info@earthbanc.io',
  facebook: 'https://www.facebook.com/earthbanc',
  twitter: 'https://twitter.com/earthbanc',
  linkedin: 'https://www.linkedin.com/company/earthbanc/',
  instagram: 'https://www.instagram.com/earthbanc.io/',
  youtube: 'https://www.youtube.com/channel/UCTaxRA8MQvIiymxxQqGJLcw/featured',
}
export const LINKS = {
  contact: 'https://earthbanc.io/contact/',
  disclaimer: 'https://earthbanc.io/disclaimer/',
  privacyPolicy: 'https://earthbanc.io/privacy-policy/',
  termsConditions: 'https://earthbanc.io/terms-conditions/',
}
export enum ResponseStatus {
  Ok = 'OK',
  Error = 'ERROR',
}
export const RATINGS_PRIORITY = [RatingType.VCS, RatingType.GS, RatingType.EB]
